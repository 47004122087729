import React, { FC } from 'react';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import styled from 'styled-components';
import { TextFieldProps } from '@mui/material';

// primereact dropdown that uses material ui for theme.
interface CustomSelectProps
  extends DropdownProps,
    Pick<TextFieldProps, 'error' | 'helperText'> {
  label?: string;
}

const Container = styled.div`
  width: 100%;
`;

const StyledSelect = styled(Dropdown)<CustomSelectProps>`
  &.p-dropdown-label .p-inputtext,
  .p-inputtext {
    padding: 11.5px;
  }
  .p-dropdown {
    border-radius: 4px;
  }
  border-color: ${({ error, theme }) =>
    error ? theme.color.error : theme.color.inputBorder};
`;

const UnstyledDropdown: FC<CustomSelectProps> = (props) => {
  const { options, virtualScrollerOptions, className, label, error } = props;

  const labelClassName = label ? 'p-float-label' : '';
  const errorClassName = error ? 'error' : '';

  return (
    <Container className={`${labelClassName} ${errorClassName}`}>
      <StyledSelect
        {...props}
        inputId="dropdown"
        virtualScrollerOptions={
          virtualScrollerOptions ||
          (options && options?.length > 100 && { itemSize: 34 }) ||
          undefined
        }
        panelClassName={className}
      />
      {label && <label htmlFor="dropdown">{label}</label>}
    </Container>
  );
};

export const Select = styled(UnstyledDropdown)`
  &.p-dropdown {
    display: flex;
  }

  &&.p-dropdown-panel {
    z-index: 2000 !important;
  }
`;
