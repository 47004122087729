import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styled from 'styled-components';
import { Menubar as PrimeReactMenuBar } from 'primereact/menubar';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import Text from 'components/UI/Text';
import { useStores } from 'stores/hooks/hooks';
import Input from 'components/UI/Input';
import RoleDialog from 'components/shared/RoleDialog';
import BackdropLoading from 'components/UI/BackdropLoading';
import Grid from 'components/UI/Grid/Grid';
import { saveAs } from 'file-saver';
import { MenuItem } from 'primereact/menuitem';
import { IS_APP_SYSTEMS_DISABLED, AREA_UNITS } from 'core/constants';
import { GrowerType, TreeGrower, ResellerTreeNode } from 'models/grower';
import { ActionMode, DialogCloseAction } from 'models/shared';
import {
  PlotModel,
  PlotExcel,
  PlotStatus,
  InitialStageModel,
} from 'models/plot';
import { UserGrowerRole } from 'models/user';
import api from 'Api/ApiMethods';
import { ModelType } from 'models/features';
import MoveDialog from './ResellersTable/MoveDialog/MoveDialog';
import GrowerManagementDialog from './GrowerManagementDialog/GrowerManagementDialog';
import PlotUploadDialog from './Plots/PlotUploadDialog/PlotUploadDialog';
import PlotStatusToggle from './Plots/PlotActivateToggle/PlotStatusToggle';
import PlotManagementDialog from './Plots/PlotManagementDialog/PlotManagementDialog';
import SystemsManagementDialog from './Systems/SystemsManagementDialog';
import AssignUsersDialog from './AssignUsersDialog/AssignUsersDialog';
import { ResellersGrowerTab } from './Resellers';
import FeatureManagementDialog from './Features/FeatureManagementDialog';

const excelFileType =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const StyledInput = styled(Input)`
  background-color: ${(p) => p.theme.palette.background.default};
`;

const StyledPrimeReactMenuBar = styled(PrimeReactMenuBar)`
  .p-submenu-list {
    width: auto;
  }

  .p-menuitem-text {
    text-wrap: nowrap;
  }
`;

const SelectedNodeTitle = styled(Text)`
  color: ${({ theme }) => theme.color.text};
  font-weight: 600;
`;

const defaultUnitValue = AREA_UNITS[0].id;

interface MenuBarEndComponentsProps {
  handleSearchChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  isPlots: boolean;
  searchPlaceholder: string;
}

const MenuBarEndComponents: FC<MenuBarEndComponentsProps> = ({
  handleSearchChange,
  isPlots,
  searchPlaceholder,
}) => {
  const { resellersStore } = useStores();
  const { selectedRowsPlots, selectedTreeNode } = resellersStore;
  const selectedPlot = selectedRowsPlots[0];
  const nodeTitle = selectedTreeNode?.data?.name;
  const isActivateToggleVisible =
    isPlots &&
    selectedRowsPlots.length === 1 &&
    selectedPlot.status === PlotStatus.Draft;
  // const plotCoordinates = usePlotCoordinates(resellersStore.selectedRowsPlots);

  return (
    <Grid gap="1rem" direction="row">
      {/* <WeatherToolSelect
        disabledPlotsOptions={resellersStore.selectedRowsPlots.length === 0}
        coordinates={plotCoordinates}
      /> */}
      <SelectedNodeTitle>{nodeTitle}</SelectedNodeTitle>
      {isActivateToggleVisible && <PlotStatusToggle />}
      <StyledInput
        size="small"
        type="text"
        placeholder={searchPlaceholder}
        onChange={handleSearchChange}
      />
    </Grid>
  );
};

const Menubar: FC<{ selectedTab: number }> = ({ selectedTab }) => {
  const { t } = useTranslation('grower');
  const {
    resellersStore,
    membersStore,
    plotsStore,
    systemsStore,
    featuresStore,
  } = useStores();

  const [removeResellersDialogOpen, setRemoveResellersDialogOpen] =
    useState(false);

  const [isActivateGrowerDialogVisible, setActivateGrowerDialogVisible] =
    useState<boolean>(false);

  const [moveDialog, setMoveDialog] = useState(false);
  const [definedRole, setDefinedRole] = useState<string>('');
  const [assignUsersDialogVisible, setAssignUsersDialogVisible] =
    useState<boolean>(false);

  const [editRoleDialog, setEditRoleDialog] = useState(false);
  const [unassignUsers, setUnassignUsers] = useState(false);
  const [growerManagementDialog, setGrowerManagementDialog] = useState(false);
  const [plotManagementDialog, setPlotManagementDialog] = useState(false);
  const [removeSystemDialogVisible, setRemoveSystemDialogVisible] =
    useState<boolean>(false);

  const [systemsManagementDialogVisible, setSystemsManagementDialogVisible] =
    useState(false);

  const [mlModelManagementDialogVisible, setMLModelManagementDialogVisible] =
    useState(false);

  const [removeFeatureDialogVisible, setRemoveFeatureDialogVisible] =
    useState<boolean>(false);

  const [createTitle, setCreateTitle] = useState('');
  const [growerActionMode, setGrowerActionMode] = useState<ActionMode>(
    ActionMode.CREATE,
  );

  const [systemActionMode, setSystemActionMode] = useState<ActionMode>(
    ActionMode.CREATE,
  );

  const [mlModelActionMode, setMLModelActionMode] = useState<ActionMode>(
    ActionMode.CREATE,
  );

  const [plotFileName, setPlotFileName] = useState<string>('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [plotActionMode, setPlotActionMode] = useState<ActionMode>(
    ActionMode.CREATE,
  );

  const [isPlot, setIsPlot] = useState<boolean>(false);
  const [menuModel, setMenuModel] = useState<MenuItem[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { editSystemForm } = systemsStore;
  const selectedGrowerType = resellersStore?.selectedTreeNode?.data?.growerType;
  const growerId = resellersStore.selectedGrowerId;
  const isSinglePlotSelected = resellersStore.selectedRowsPlots.length === 1;

  useEffect(() => {
    membersStore.getGrowersRoles(resellersStore.resellerUsers[0]?.userId || 1);
  }, [membersStore, resellersStore]);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      resellersStore.setGlobalFilter((event.target.value || '').trim());
    },
    [resellersStore],
  );

  const onCloseRemoveDialog = useCallback(
    (response: DialogCloseAction) => {
      if (response === DialogCloseAction.Confirmed) {
        resellersStore.removeSelectedGrowers();
      }

      setRemoveResellersDialogOpen(false);
    },
    [resellersStore],
  );

  const onCloseActivateDialog = useCallback(
    (response: DialogCloseAction) => {
      if (
        response === DialogCloseAction.Confirmed &&
        resellersStore.selectedGrowersForActivation.length
      ) {
        const newStatus =
          !resellersStore.selectedGrowersForActivation[0]?.isActive;

        resellersStore
          .updateGrowersStatus(
            resellersStore.selectedGrowersForActivation,
            newStatus,
          )
          .then(() => {
            setActivateGrowerDialogVisible(false);
          });
      } else {
        setActivateGrowerDialogVisible(false);
      }
    },
    [resellersStore],
  );

  const onCloseUnassignDialog = useCallback(
    (response: DialogCloseAction) => {
      if (response === DialogCloseAction.Confirmed) {
        resellersStore.unassignUsersFromGrower(
          growerId,
          resellersStore.selectedRowsUsers,
        );
      }

      setUnassignUsers(false);
    },
    [resellersStore, growerId],
  );

  const handleMoveDialogClose = useCallback(() => {
    setMoveDialog(false);
    resellersStore.setSelectedRows({ selection: [], activeRow: null });
  }, [resellersStore]);

  const onEditDialogRole = useCallback(() => {
    const name =
      resellersStore.selectedRowsUsers.length === 1
        ? resellersStore.selectedRowsUsers[0]?.userRole
        : '';

    setDefinedRole(name || '');
    setEditRoleDialog(true);
  }, [resellersStore]);

  const onCloseEditRoleDialog = useCallback(
    (response: DialogCloseAction, selectedRole: UserGrowerRole | null) => {
      if (response === DialogCloseAction.Confirmed && selectedRole) {
        resellersStore.editResellerUserRole(selectedRole);
      }

      setEditRoleDialog(false);
    },
    [resellersStore],
  );

  const onCreate = useCallback(
    (growerType: GrowerType) => {
      resellersStore.setSelectedRows({ selection: [], activeRow: null });
      resellersStore.growerAction.name = '';
      resellersStore.growerAction.growerType = growerType;
      resellersStore.growerAction.resellerId = growerId || 0;

      setGrowerActionMode(ActionMode.CREATE);
      setCreateTitle(
        growerType === GrowerType.Reseller
          ? t('create_reseller')
          : t('create_grower'),
      );

      setGrowerManagementDialog(true);
    },
    [t, growerId, resellersStore],
  );

  const onEdit = useCallback(() => {
    resellersStore.growerAction =
      resellersStore.selectedRows[0] || ({} as ResellerTreeNode);

    setGrowerActionMode(ActionMode.EDIT);
    setCreateTitle(
      t('edit_entity', {
        type:
          resellersStore.growerAction.growerType === GrowerType.Reseller
            ? 'Reseller'
            : 'Grower',
      }),
    );

    setGrowerManagementDialog(true);
  }, [t, resellersStore]);

  const handleGrowerManagementDialogClose = useCallback(() => {
    setGrowerManagementDialog(false);
    if (growerActionMode === ActionMode.EDIT) {
      const grower = resellersStore.tableValues.find(
        (g) => g?.id === resellersStore.growerAction.id,
      );

      resellersStore.setSelectedRows({
        selection: [],
        activeRow: (grower as TreeGrower) ?? null,
      });
    }
  }, [growerActionMode, resellersStore]);

  const downloadPlotTemplate = useCallback(async () => {
    const file = await api.getPlotTemplate();

    saveAs(
      new Blob([file], { type: 'application/octet-stream' }),
      'plot_template.xlsx',
    );
  }, []);

  const getSelectedPlotFields = useCallback((): Partial<PlotModel> => {
    const plot = resellersStore.selectedRowsPlots[0];

    return {
      growerId,
      plotId: plot.id,
      id: plot.id,
      area: {
        unit: plot.area?.unit,
        val: plot.area?.val,
      },
      initialStage:
        {
          date: plot.initialStage?.date,
          stageId: plot.initialStage?.stageId,
        } || {},
      crop: plot.crop,
      cropProtocolId: plot.cropProtocolId,
      previousProtocolId: plot.previousProtocolId,
      name: plot.name,
      description: plot.description,
      growingType: plot.growingType,
      growthMethod: plot.growthMethod,
      irrigationMethod: plot.irrigationMethod,
      latitude: plot.latitude,
      longitude: plot.longitude,
      plantTime: plot.plantTime,
      startTime: plot.startTime,
      sampleFrequency: plot.sampleFrequency || 'NORMAL',
      serviceLevel: plot.serviceLevel,
      soil: plot.soil,
      seasonTime: plot.startTime,
      variety: plot.variety,
      category: plot.category,
      geojson: plot.geojson,
    } as Partial<PlotModel>;
  }, [resellersStore.selectedRowsPlots, growerId]);

  const getDefaultSelectData = useCallback(() => {
    plotsStore.getGrowingType();
    plotsStore.getIrrigationMethods();
    plotsStore.getServiceLevel();
    plotsStore.getSoilType();
  }, [plotsStore]);

  const getCropSelectData = useCallback(() => {
    plotsStore.getCropTypes(growerId);
    plotsStore.getPenologicalStages();
    plotsStore.getGrowingMethod();
    plotsStore.getCategories(
      plotsStore.plotAction.growerId,
      plotsStore.plotAction.crop,
    );

    plotsStore.getVarieties(
      plotsStore.plotAction.growerId,
      plotsStore.plotAction.crop,
    );
  }, [plotsStore, growerId]);

  const openPlotCreateModal = useCallback(() => {
    setPlotActionMode(ActionMode.CREATE);
    getDefaultSelectData();
    const defaultPlot = {
      growerId,
      area: {
        unit: defaultUnitValue,
      },
      initialStage: {},
      variety: '',
      sampleFrequency: 'NORMAL',
      startTime: new Date().getTime(),
      status: PlotStatus.Draft,
    };

    plotsStore.setPlotAction(defaultPlot as PlotModel);
    setPlotManagementDialog(true);
  }, [plotsStore, growerId, getDefaultSelectData]);

  const openPlotEditModal = useCallback(() => {
    setPlotActionMode(ActionMode.EDIT);
    getDefaultSelectData();

    const plotToEdit = getSelectedPlotFields();

    plotsStore.setPlotAction(plotToEdit as PlotModel);
    getCropSelectData();

    if (plotsStore.plotAction.name) {
      setPlotManagementDialog(true);
    }
  }, [
    plotsStore,
    getSelectedPlotFields,
    getDefaultSelectData,
    getCropSelectData,
  ]);

  const openPlotDuplicateModal = useCallback(() => {
    setPlotActionMode(ActionMode.CREATE);
    getDefaultSelectData();

    const selectedPlot = getSelectedPlotFields();
    const duplicatePlotData: Partial<PlotModel> = {
      ...selectedPlot,
      name: `${selectedPlot.name} copy`,
      geojson: null,
      area: { ...selectedPlot.area, val: 0 },
      initialStage: {} as InitialStageModel,
      startSeason: undefined,
    };

    plotsStore.setPlotAction(duplicatePlotData as PlotModel);
    plotsStore.plotManagementValidation.location = true;
    getCropSelectData();

    if (plotsStore.plotAction.name) {
      setPlotManagementDialog(true);
    }
  }, [
    plotsStore,
    getSelectedPlotFields,
    getDefaultSelectData,
    getCropSelectData,
  ]);

  const handlePlotManagementDialogClose = useCallback(() => {
    setPlotManagementDialog(false);
    plotsStore.resetStore();
  }, [plotsStore]);

  const handleSystemsManagementDialogClose = useCallback(() => {
    setSystemsManagementDialogVisible(false);
    systemsStore.resetStore();
  }, [systemsStore]);

  const handleMLModelManagementDialogClose = useCallback(() => {
    featuresStore.setSelectedSensors([]);
    featuresStore.setFeatureType({} as ModelType);
    setMLModelManagementDialogVisible(false);
  }, [featuresStore]);

  const handleFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target?.files?.[0]) {
        const fileName = e.target?.files?.[0]?.name;
        const res: PlotExcel[] = await resellersStore.uploadExcelPlots(
          e.target.files[0],
        );

        if (res && fileName) {
          setPlotFileName(fileName);
        }
      }

      e.target.value = '';
    },
    [resellersStore],
  );

  const handlePlotDialogClose = useCallback(() => {
    setPlotFileName('');
  }, []);

  const showAssignUsersDialog = useCallback(() => {
    setAssignUsersDialogVisible(true);
  }, []);

  const hideAssignUsersDialog = useCallback(() => {
    setAssignUsersDialogVisible(false);
  }, []);

  const handleSystemDialogOpen = useCallback((mode: ActionMode) => {
    setSystemsManagementDialogVisible(true);
    setSystemActionMode(mode);
  }, []);

  const handleMlModelDialogOpen = useCallback((mode: ActionMode) => {
    setMLModelManagementDialogVisible(true);
    setMLModelActionMode(mode);
  }, []);

  const handleRemoveSystemSubmit = useCallback(
    (value: DialogCloseAction) => {
      if (value !== DialogCloseAction.Confirmed) {
        setRemoveSystemDialogVisible(false);
        return;
      }

      const systemId = resellersStore.selectedRowsSystems[0]?.id;
      if (systemId) {
        resellersStore.removeGrowerSystem(systemId).then(() => {
          setRemoveSystemDialogVisible(false);
        });
      }
    },
    [resellersStore],
  );

  const handleRemoveFeatureSubmit = useCallback(
    (value: DialogCloseAction) => {
      if (value !== DialogCloseAction.Confirmed) {
        setRemoveFeatureDialogVisible(false);
        return;
      }

      const features = featuresStore.selectedFeatures;
      if (features) {
        featuresStore.deactivateFeature().then(() => {
          setRemoveFeatureDialogVisible(false);
        });
      }
    },
    [featuresStore],
  );

  const activateMenuOptions: MenuItem[] = useMemo(() => {
    const selectedGrower = resellersStore.selectedGrowersForActivation[0];
    const isGrowerActive = selectedGrower?.isActive;
    const isGrowerHasActiveEntities = selectedGrower?.growers?.some(
      (grower) => grower.isActive,
    );

    const isActivationEnabled =
      resellersStore.selectedGrowersForActivation.length &&
      (!isGrowerHasActiveEntities || !selectedGrower.isActive);

    const isTableGrowerRowSelected =
      selectedGrowerType === GrowerType.Reseller &&
      selectedTab === ResellersGrowerTab.Growers &&
      !!resellersStore.selectedRows.length;

    const isActionVisible =
      selectedTab === ResellersGrowerTab.Details || isTableGrowerRowSelected;

    return [
      {
        label: isGrowerActive ? t('deactivate') : t('activate'),
        icon: 'fa fa-hand-paper',
        disabled: !isActivationEnabled,
        visible: isActionVisible,
        command: () => {
          setActivateGrowerDialogVisible(true);
        },
      },
    ];
  }, [
    t,
    resellersStore.selectedRows.length,
    resellersStore.selectedGrowersForActivation,
    selectedGrowerType,
    selectedTab,
  ]);

  const resellerItems: MenuItem[] = useMemo(
    () => [
      {
        label: t('create_reseller'),
        icon: 'pi pi-fw pi-plus',
        command: () => {
          onCreate(GrowerType.Reseller);
        },
      },
      {
        label: t('create_grower'),
        icon: 'pi pi-fw pi-plus',
        command: () => {
          onCreate(GrowerType.Grower);
        },
      },
      {
        label: t('edit'),
        icon: 'pi pi-fw pi-pencil',
        disabled: !resellersStore.growerTableMultiSelection.activeRow,
        command: onEdit,
      },
      {
        label: t('move'),
        disabled: true,
        visible: false,
        command: () => {
          setMoveDialog(true);
        },
      },
      {
        label: t('remove'),
        disabled:
          !resellersStore.selectedRows.length ||
          resellersStore.isEntityHaveChildren,
        command: () => {
          setRemoveResellersDialogOpen(true);
        },
        icon: 'pi pi-fw pi-trash',
      },
      ...activateMenuOptions,
    ],
    [
      activateMenuOptions,
      onCreate,
      onEdit,
      resellersStore.growerTableMultiSelection.activeRow,
      resellersStore.isEntityHaveChildren,
      resellersStore.selectedRows,
      t,
    ],
  );

  const itemsUsers: MenuItem[] = useMemo(
    () => [
      {
        label: t('assign_users'),
        command: showAssignUsersDialog,
      },
      {
        label: t('edit_role'),
        disabled: !resellersStore.selectedRowsUsers.length,
        icon: 'pi pi-fw pi-pencil',
        command: onEditDialogRole,
      },
      {
        label: t('unassign'),
        disabled: !resellersStore.selectedRowsUsers.length,
        command: () => {
          setUnassignUsers(true);
        },
      },
    ],
    [
      onEditDialogRole,
      showAssignUsersDialog,
      resellersStore.selectedRowsUsers.length,
      t,
    ],
  );

  const PlotItems: MenuItem[] = useMemo(
    () => [
      {
        label: t('plots'),
        icon: 'pi pi-fw pi-file',
        items: [
          {
            label: t('new_plot'),
            icon: 'pi pi-fw pi-plus',
            command: openPlotCreateModal,
          },
          {
            label: t('import_plots'),
            icon: 'pi pi-fw pi-upload',
            command: () => {
              inputRef?.current?.click();
            },
          },
          {
            label: t('download_template'),
            icon: 'pi pi-fw pi-download',
            command: downloadPlotTemplate,
          },
        ],
      },
      {
        label: t('edit_plot'),
        icon: 'pi pi-fw pi-pencil',
        command: openPlotEditModal,
        disabled: !isSinglePlotSelected,
      },
      {
        label: t('duplicate_plot'),
        icon: 'pi pi-fw pi-copy',
        command: openPlotDuplicateModal,
        disabled: !isSinglePlotSelected,
      },
      {
        label: t('grower_view'),
        icon: 'fa fa-binoculars',
        disabled: true,
      },
      {
        label: t('remove'),
        icon: 'fa fa-times',
        disabled: true,
      },
    ],
    [
      t,
      openPlotCreateModal,
      openPlotEditModal,
      openPlotDuplicateModal,
      downloadPlotTemplate,
      isSinglePlotSelected,
    ],
  );

  const isSystemSelected = useMemo(
    () =>
      editSystemForm.selectedSystem &&
      editSystemForm.selectedSystem?.id ===
      resellersStore.selectedRowsSystems[0]?.id,
    [editSystemForm.selectedSystem, resellersStore.selectedRowsSystems],
  );

  const systemItems: MenuItem[] = useMemo(
    () => [
      {
        label: t('add_entity', { type: 'System' }),
        icon: 'pi pi-fw pi-plus',
        command: () => handleSystemDialogOpen(ActionMode.CREATE),
        disabled:
          IS_APP_SYSTEMS_DISABLED || selectedTab !== ResellersGrowerTab.Systems,
      },
      {
        label: t('edit_entity', { type: 'System' }),
        icon: 'pi pi-fw pi-pencil',
        command: () => handleSystemDialogOpen(ActionMode.EDIT),
        disabled: IS_APP_SYSTEMS_DISABLED || !isSystemSelected,
      },
      {
        label: t('remove_entity', { type: 'System' }),
        icon: 'pi pi-fw pi-trash',
        command: () => setRemoveSystemDialogVisible(true),
        disabled: IS_APP_SYSTEMS_DISABLED || !isSystemSelected,
      },
    ],
    [t, isSystemSelected, selectedTab, handleSystemDialogOpen],
  );

  const featuresItems: MenuItem[] = useMemo(
    () => [
      {
        label: t('add_entity', { type: 'Feature' }),
        icon: 'pi pi-fw pi-plus',
        command: () => handleMlModelDialogOpen(ActionMode.CREATE),
        disabled: selectedTab !== ResellersGrowerTab.Features,
      },
      {
        label: t('edit_entity', { type: 'Feature' }),
        icon: 'pi pi-fw pi-pencil',
        command: () => handleSystemDialogOpen(ActionMode.EDIT),
        disabled: true,
      },
      {
        label: t('remove_entity', { type: 'Feature' }),
        icon: 'pi pi-fw pi-trash',
        command: () => setRemoveFeatureDialogVisible(true),
        disabled:
          selectedTab !== ResellersGrowerTab.Features ||
          featuresStore.selectedFeatures.length === 0,
      },
    ],
    [
      t,
      selectedTab,
      featuresStore.selectedFeatures.length,
      handleMlModelDialogOpen,
      handleSystemDialogOpen,
    ],
  );

  useEffect(() => {
    setIsPlot(false);
    if (!resellersStore.selectedTreeNode) {
      setMenuModel([]);
    } else if (selectedTab === ResellersGrowerTab.Users) {
      setMenuModel(itemsUsers);
    } else if (selectedTab === ResellersGrowerTab.Plots) {
      setMenuModel(PlotItems);
      setIsPlot(true);
    } else if (selectedTab === ResellersGrowerTab.Systems) {
      setMenuModel(systemItems);
    } else if (selectedTab === ResellersGrowerTab.Features) {
      setMenuModel(featuresItems);
    } else if (
      selectedGrowerType === GrowerType.Reseller &&
      selectedTab === ResellersGrowerTab.Growers
    ) {
      setMenuModel(resellerItems);
    } else if (selectedTab === ResellersGrowerTab.Details) {
      setMenuModel(activateMenuOptions);
    } else {
      setMenuModel([]);
    }
  }, [
    itemsUsers,
    PlotItems,
    systemItems,
    resellerItems,
    selectedTab,
    selectedGrowerType,
    activateMenuOptions,
    resellersStore.selectedRows,
    resellersStore.selectedTreeNode,
    featuresItems,
  ]);

  const selectedGrowerForActivation =
    resellersStore.selectedGrowersForActivation[0];

  return (
    <div>
      {removeResellersDialogOpen && (
        <ConfirmDialog
          backText={t('cancel')}
          buttonText={t('remove')}
          isOpen={removeResellersDialogOpen}
          title={t('confirm_dialog_reseller_delete_title')}
          message={t('confirm_dialog_reseller_delete_message')}
          onClose={onCloseRemoveDialog}
        />
      )}
      {isActivateGrowerDialogVisible && (
        <ConfirmDialog
          onClose={onCloseActivateDialog}
          loading={resellersStore.isUpdatingGrowerStatus}
          disabled={resellersStore.isUpdatingGrowerStatus}
          backText={t('cancel')}
          buttonText={
            selectedGrowerForActivation?.isActive
              ? t('deactivate')
              : t('activate')
          }
          isOpen={isActivateGrowerDialogVisible}
          title={i18next.t('dialog:confirm_dialog_title', {
            action: selectedGrowerForActivation?.isActive
              ? 'Deactivate'
              : 'Activate',
          })}
          message={i18next.t('dialog:confirm_dialog_action_message', {
            action: selectedGrowerForActivation?.isActive
              ? 'deactivate'
              : 'activate',
            entity:
              selectedGrowerForActivation?.growerType === GrowerType.Grower
                ? 'growers'
                : 'resellers',
          })}
        />
      )}
      {unassignUsers && (
        <ConfirmDialog
          backText={t('cancel')}
          buttonText={t('unassign')}
          isOpen={unassignUsers}
          title={t('confirm_dialog_reseller_unassign_title')}
          message={t('confirm_dialog_reseller_unassign_message')}
          onClose={onCloseUnassignDialog}
        />
      )}
      {moveDialog && (
        <MoveDialog
          onHide={handleMoveDialogClose}
          visible={moveDialog}
          title={`${t('move')} ${t('to')}`}
        />
      )}
      {editRoleDialog && (
        <RoleDialog
          backText={t('cancel')}
          buttonText={t('edit')}
          isOpen={editRoleDialog}
          title={t('users:edit_role')}
          message={t('users:supplant_role')}
          onClose={onCloseEditRoleDialog}
          definedRole={definedRole}
        />
      )}
      {growerManagementDialog && (
        <GrowerManagementDialog
          onHide={handleGrowerManagementDialogClose}
          visible={growerManagementDialog}
          title={createTitle}
          mode={growerActionMode}
        />
      )}
      <BackdropLoading open={uploadLoading} />
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        accept={excelFileType}
      />
      <PlotUploadDialog
        fileName={plotFileName}
        onClose={handlePlotDialogClose}
      />
      {plotManagementDialog && (
        <PlotManagementDialog
          onHide={handlePlotManagementDialogClose}
          visible={plotManagementDialog}
          title={
            plotActionMode === ActionMode.CREATE
              ? `${t('create')} ${t('plot')}`
              : `${t('edit')} ${t('plot')}`
          }
          mode={plotActionMode}
        />
      )}
      {systemsManagementDialogVisible && (
        <SystemsManagementDialog
          onHide={handleSystemsManagementDialogClose}
          visible={systemsManagementDialogVisible}
          title={
            systemActionMode === ActionMode.CREATE
              ? `${t('add')} ${t('system')}`
              : `${t('edit')} ${t('system')}`
          }
          mode={systemActionMode}
        />
      )}
      {mlModelManagementDialogVisible && (
        <FeatureManagementDialog
          onHide={handleMLModelManagementDialogClose}
          visible={mlModelManagementDialogVisible}
          title={
            mlModelActionMode === ActionMode.CREATE
              ? `${t('add')} ${t('ai_feature')}`
              : `${t('edit')} ${t('ai_feature')}`
          }
          mode={systemActionMode}
        />
      )}
      <ConfirmDialog
        backText={t('cancel')}
        buttonText={t('remove')}
        isOpen={removeSystemDialogVisible}
        title={t('remove_system_dialog_title')}
        message={t('remove_system_dialog_message')}
        loading={resellersStore.isRemovingGrowerSystem}
        disabled={resellersStore.isRemovingGrowerSystem}
        onClose={handleRemoveSystemSubmit}
      />
      <ConfirmDialog
        backText={t('cancel')}
        buttonText={t('remove')}
        isOpen={removeFeatureDialogVisible}
        title={t('remove_feature_dialog_title')}
        message={t('remove_feature_dialog_message')}
        onClose={handleRemoveFeatureSubmit}
      />
      {assignUsersDialogVisible && (
        <AssignUsersDialog
          onHide={hideAssignUsersDialog}
          visible={assignUsersDialogVisible}
          title={t('assign_users')}
        />
      )}

      <StyledPrimeReactMenuBar
        model={menuModel}
        end={
          <MenuBarEndComponents
            handleSearchChange={handleSearchChange}
            isPlots={isPlot}
            searchPlaceholder={t('search')}
          />
        }
      />
    </div>
  );
};

export default observer(Menubar);
