import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { SystemVariant } from 'models/systems';
import { rootStore } from 'stores/contexts/storesContext';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_URL = process.env.REACT_APP_BASE_URL_AUTH;

export const ApiEndpoints = {
  postLogin: (email: string, password: string) =>
    `/public/users/login?email=${email}&password=${password}`,
  postForgotPassword: (email: string) =>
    `/public/users/password/forgot?email=${email}&linkTemplate=${process.env.REACT_APP_FORGOT_PASS_TEMP_LINK}`,
  postResetPassword: (
    newPassword: string,
    email: string,
    passwordToken: string,
  ) =>
    `/public/users/password/reset?email=${email}&passwordToken=${passwordToken}&newPassword=${newPassword}`,
  postAccessOtc: (phoneNumber: string) =>
    `/public/access-otc/backoffice?phone=${phoneNumber}`,
  postVerifyOtc: (requestId: string, otp: string) =>
    `/public/verify-otc?requestId=${requestId}&otp=${otp}`,
  postLogout: () => `/public/users/logout`, // TBD: call function on logout
  postRefreshToken: (refreshToken: string) =>
    `/public/users/renew-access-token?refresh_token=${refreshToken}`,
  getSupportedLocales: () => '/public/supported-locales',
  getCropProtocol: (protocolId: string) => `/api/v3/protocols/${protocolId}`,
  getAllProtocols: () => `/api/v3/protocols`,
  postUploadExcelProtocols: () => `/api/v3/protocols/model`,
  postUploadJSONProtocols: () => `/api/v3/protocols/model`,
  getGrowers: () => '/api/v3/protocols/growers',
  getCropTypes: () => '/api/v3/crop-types',
  getPlots: () => '/api/v3/protocols/plots',
  getGrowerPlots: (growerId: number, historic: boolean) =>
    `/api/v3/growers/${growerId}/plots?historic=${historic}`,
  getGrowerSystems: (growerId: number) => `/api/v3/growers/${growerId}/systems`,
  getSystemTypes: () => '/api/v3/system-types',
  getSensorTypes: () => '/api/v3/sensor-types',
  postCreateSystem: () => '/api/v3/growers/systems',
  deleteSystem: (systemId: number) => `/api/v3/systems/${systemId}`,
  putUpdateSystem: (systemId: number) => `/api/v3/systems/${systemId}`,
  postBatchCreateSensors: (growerId: number, systemId: number) =>
    `/api/v3/growers/${growerId}/systems/${systemId}/sensors/batch`,
  postCreateSensor: (growerId: number, sensorQuery: string) =>
    `/api/v3/growers/${growerId}/sensors${sensorQuery}`,
  putUpdateSensor: () => `/api/v3/sensors`,
  deleteSensor: (growerId: number, systemId: number, sensorId: number) =>
    `/api/v3/growers/${growerId}/systems/${systemId}/sensors/${sensorId}/stop`,
  putAttachMultiplePlotToSensor: (plotId: number) =>
    `/api/v3/plots/${plotId}/sensors`,
  deleteSensorsFromPlot: (plotId: number, sensorId: number) =>
    `/api/v3/plots/${plotId}/sensors/${sensorId}`,
  getSystemActiveSensors: (growerId: number, systemId: number) =>
    `/api/v3/growers/${growerId}/systems/${systemId}/sensors/active`,
  getSystemSensors: (growerId: number, systemId: number) =>
    `/api/v3/growers/${growerId}/systems/${systemId}/sensors`,
  getPlotSensors: (plotId: number) => `/api/v3/plots/${plotId}/systems-sensors`,
  getSystemValves: (systemId: number) => `/api/v3/systems/${systemId}/valves`,
  getGrowerSensors: (growerId: number) => `/api/v3/growers/${growerId}/sensors`,
  getSystemConfigurationSchema: (systemType: SystemVariant) =>
    `/api/v3/system-types/${systemType}`,
  getPlotFilters: (protocolId: string) =>
    `/api/v3/protocols/${protocolId}/plot-stats`,
  getPlotTemplate: () => '/api/v3/growers/plots/batch-template-file',
  putDisableUsers: () => '/api/v3/disable',
  putEnableUsers: () => '/api/v3/enable',
  deleteCropProtocol: (growerId: number, protocolId: string) =>
    `/api/v3/growers/${growerId}/protocols/${protocolId}`,
  // Users
  getAllUsers: () => '/api/v3/users',
  postUser: () => '/api/v3/users',
  putUsers: () => '/api/v3/users',
  deleteUsers: () => '/api/v3/users',
  getUserPreferences: () => '/api/v3/user-preferences/default',
  getAllCompanyRoles: () => '/api/v3/company-roles',
  postUploadUsersExcel: () => '/api/v3/users/parse-excel',
  postCreateUsers: () => '/api/v3/users/upload-batch',
  getUsersExcelTemplate: () => '/api/v3/users/excel-template',
  // UserToGrower Relationship
  getUserGrowers: (
    userId: number,
    offset: number,
    cnt: number,
    isAssigned: boolean,
  ) =>
    `/api/v3/users/${userId}/growers?offset=${offset}&cnt=${cnt}&isAssigned=${isAssigned}`,
  getUserGrowersRoles: (userId: number) =>
    `/api/v3/users/${userId}/growers/roles`,
  putUserGrowersRole: (userId: number, roleId: number) =>
    `/api/v3/users/${userId}/growers/roles/${roleId}`,
  deleteUnassignUserGrowers: (userId: number) =>
    `/api/v3/users/${userId}/growers/assignment`,
  putAssignUserGrowers: (userId: number, roleId: number) =>
    `/api/v3/growers/users/${userId}/roles/${roleId}/assignment`,
  // Reseller Page
  getAllGrowers: () => `/api/v3/growers`,
  postGrower: () => `/api/v3/growers`,
  putGrowers: () => '/api/v3/growers',
  deleteGrowers: () => '/api/v3/growers',
  putUpdateGrowerStatus: (growerId: number, status: boolean) =>
    `/api/v3/growers/${growerId}?active=${status}`,
  getResellerUsers: (growerId: number) => `/api/v3/growers/${growerId}/users`,
  getUserNotification: (userId: number) =>
    `/api/v3/reseller/userNotification?userId=${userId}`,
  putMoveGrowerToReseller: (growerId?: number) =>
    `/api/v3/reseller/growers/move/${growerId}`, // TBD: Need to match the endpoint when it will be added to BE
  getResellerPlots: (resellerId: number, historic: boolean) =>
    `/api/v3/growers/${resellerId}/plots?historic=${historic}`,
  postUploadPlotsExcel: (growerId: number | null) =>
    `/api/v3/growers/${growerId}/plots/excel`,
  postCreatePlotsFromJson: (growerId: number | null) =>
    `/api/v3/growers/${growerId}/plots/json`,
  // Create Plot
  putUpdatePlotsStatus: (growerId: number | null) =>
    `/api/v3/growers/${growerId}/plots/status`,
  getGrowingTypes: () => '/api/v3/grow-types',
  getSoilTypes: () => '/api/v3/soil-types',
  getServiceLevels: () => '/api/v3/service-levels',
  getIrrigationMethods: () => '/api/v3/plots/irrigation-method/types',
  getGrowerCropTypes: (growerId: number) =>
    `/api/v3/growers/${growerId}/crop-types?visitAncestors=true`,
  getCropCategories: (growerId: number | null, cropId: number | null) =>
    `/api/v3/grower/${growerId}/crops/${cropId}/categories`,
  getCropVarieties: (growerId: number | null, cropId: number | null) =>
    `/api/v3/growers/${growerId}/crops/${cropId}/varieties`,
  getCropProtocolId: (
    growerId: number | null,
    cropId: number | null,
    latitude: number,
    longitude: number,
    variety: string,
    category: string,
  ) => {
    const varietyParam = variety ? `&cropVariety=${variety}` : '';
    const categoryParam = category ? `&cropCategory=${category}` : '';
    return `/api/v3/growers/${growerId}/crops/${cropId}/protocols?latitude=${latitude}&longitude=${longitude}&idOnly=true${varietyParam}${categoryParam}`;
  },
  postCreatePlot: (growerId: number | null) =>
    `/api/v3/growers/${growerId}/plots`,
  getPhenologicalStages: (
    protocolId: string | null,
    plantDate: number | null,
    plotId: number | null,
  ) =>
    `/api/v3/protocols/${protocolId}/phenological-stages?plantDate=${plantDate}${
      plotId ? `&plotId=${plotId}` : ''
    }`,
  getGrowingMethods: (
    protocolId: string | null,
    plantDate: number | null,
    plotId: number | null,
  ) =>
    `/api/v3/protocols/${protocolId}/grow-types?plantDate=${plantDate}${
      plotId ? `&plotId=${plotId}` : ''
    }`,
  putUpdatePlot: (growerId: number | null, plotId: number | null) =>
    `/api/v3/growers/${growerId}/plots/${plotId}?state=UPDATE_PLOT`,
  // AI Features
  getFeaturesSchema: () => '/api/v3/mlmodels',
  postFeature: (plotId: number | null, featureType: string) =>
    `/api/v3/mlmodels/plots/${plotId}/mlsensors/activation?modelType=${featureType}`,
  getFeaturesByPlot: (plotId: number, modelType: string) =>
    `/api/v3/mlmodels/plots/${plotId}/mlsensors?modelType=${modelType}`,
  getFeaturesByGrower: (growerId: number) =>
    `/api/v3/mlmodels/growers/${growerId}/mlsensors`,
  deleteFeature: (plotId: number | null, featureId: number | null) =>
    `/api/v3/mlmodels/plots/${plotId}/mlsensors/${featureId}`,
  getRunAnomalyDetection: (growerId: string | number) =>
    `api/v3/run/anomaly_detection?growerId=${growerId}`,
  getCoupons: () => `/api/v3/coupon`,
};

const Api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const ApiPublic = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const AuthApi = axios.create({
  baseURL: AUTH_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

Api.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  async (error: Error | AxiosError) => {
    if (axios.isAxiosError(error)) {
      if ((error as AxiosError).response?.status === 401) {
        try {
          if (!rootStore.userStore.isRefreshingToken) {
            await rootStore.userStore.refreshUserToken();
          } else {
            await rootStore.userStore.isRefreshingToken;
          }

          if (rootStore.userStore.accessTokenBlock?.access_token) {
            const axiosRequestConfig = error.config as AxiosRequestConfig;
            axiosRequestConfig.headers = {
              ...axiosRequestConfig.headers,
              Authorization: `Bearer ${rootStore.userStore.accessTokenBlock?.access_token}`,
            };

            return await axios.request(axiosRequestConfig);
          }
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.status === 401) {
            rootStore.userStore.logOut();
          } else {
            return Promise.reject(e);
          }
        }
      }
    }

    return Promise.reject(error);
  },
);

export const setAuthorizationHeader = (token?: string) => {
  Api.interceptors.request.use((config) => {
    if (config && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });
};

export default Api;
export { AuthApi, ApiPublic };
