import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  TextFieldProps,
} from '@mui/material';
import styled from 'styled-components';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import Space from 'components/UI/Space';
import { ActionMode, DialogOnCloseReason } from 'models/shared';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SupportCouponCountries } from 'core/enums';
import { DropdownChangeParams } from 'primereact/dropdown';
import MultiSelect from 'components/UI/MultiSelect';
import { Coupon } from 'stores/couponsStore';

const eligibleCountries = [
  SupportCouponCountries.AU,
  SupportCouponCountries.ES,
  SupportCouponCountries.FR,
  SupportCouponCountries.IL,
  SupportCouponCountries.IN,
  SupportCouponCountries.MA,
  SupportCouponCountries.MX,
];

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  input {
    box-sizing: unset;
  }
`;

const StyledMultiSelect = styled(MultiSelect)``;

const NoWrappingText = styled(Text)`
  white-space: nowrap;
  min-width: 30%;
  max-width: 30%;
`;

const EMPTY_COUPON = {
  code: '',
  name: '',
  validFromUTC: null, // note, will convert to UTC timestamp before sending to server
  validToUTC: null, // note, will convert to UTC timestamp before sending to server
  growerId: '',
  maxTransactions: null,
  params: { eligibleCountries: [] as string[] },
};

const convertDateToUtcTimestamp = (date: Date) => {
  const dateAsUtcTimestamp = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  );

  return dateAsUtcTimestamp;
};

interface GrowerManagementDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const CouponManagementDialog: FC<GrowerManagementDialogProps> = observer(
  ({ visible = false, onHide, title, mode }) => {
    const { t } = useTranslation('coupons');
    const { couponsStore } = useStores();
    const [couponEdit, setCouponEdit] = useState(EMPTY_COUPON);
    const [growerNameEdit, setGrowerNameEdit] = useState('name');

    const [initialActionString, setInitialActionString] = useState('');
    useEffect(() => {
      if (initialActionString || growerNameEdit === '') {
        return;
      }

      setInitialActionString(growerNameEdit);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isValidForm = useMemo(() => {
      if (
        couponEdit.code?.trim() &&
        couponEdit.name?.trim() &&
        couponEdit.validFromUTC &&
        couponEdit.validToUTC &&
        couponEdit.growerId?.trim() &&
        couponEdit.maxTransactions &&
        couponEdit.params.eligibleCountries
      ) {
        return true;
      }

      return false;
    }, [couponEdit]);

    const closeDialog = useCallback(() => {
      setInitialActionString('');
      onHide();
    }, [onHide]);

    const handleCloseDialog = useCallback(
      (event: any, reason: DialogOnCloseReason) => {
        if (reason && reason === DialogOnCloseReason.BackDropClick) {
          return;
        }

        setGrowerNameEdit('');
        closeDialog();
      },
      [closeDialog],
    );

    const handleCloseButton = useCallback(() => {
      setGrowerNameEdit('');
      closeDialog();
    }, [closeDialog]);

    const handleChange = useCallback(
      (
        event: ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >,
      ) => {
        const { value } = event.target;
        setCouponEdit((prev) => ({
          ...prev,
          [event.target.name]: value,
        }));
      },
      [],
    );

    const handleChangeSelect = useCallback((event: DropdownChangeParams) => {
      const valueArray = event.value as string[];
      setCouponEdit((prev) => ({
        ...prev,
        params: { eligibleCountries: valueArray },
      }));
    }, []);

    const handleDateChange = useCallback((event: unknown, id: string) => {
      const date = new Date(event as Date);
      setCouponEdit((prev) => ({
        ...prev,
        [id]: date,
      }));
    }, []);

    const handleSubmitClick = useCallback(async () => {
      if (isValidForm && couponEdit.validFromUTC && couponEdit.validToUTC) {
        const couponEditCopy: Coupon = {
          ...couponEdit,
          validFromUTC: convertDateToUtcTimestamp(couponEdit.validFromUTC),
          validToUTC: convertDateToUtcTimestamp(couponEdit.validToUTC),
          growerId: Number(couponEdit.growerId),
          maxTransactions: Number(couponEdit.maxTransactions),
          code: couponEdit.code,
          name: couponEdit.name,
          params: { eligibleCountries: couponEdit.params.eligibleCountries },
        };

        const result = await couponsStore.createCoupon(couponEditCopy);
        if (result) {
          onHide();
        }
      }
    }, [couponEdit, couponsStore, isValidForm, onHide]);

    return (
      <Dialog
        open={visible}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Space height={1} />
            <Grid direction="column" gap="10px">
              <Grid direction="row" gap="10px" alignSelf="start" grow>
                <NoWrappingText>{t('display_name')}:</NoWrappingText>
                <Input
                  required
                  name="code"
                  label={t('display_name')}
                  id="code"
                  value={couponEdit.code}
                  variant="outlined"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid direction="row" gap="10px" alignSelf="start" grow>
                <NoWrappingText>{t('name')}:</NoWrappingText>
                <Input
                  required
                  name="name"
                  label={t('name')}
                  id="name"
                  value={couponEdit.name}
                  variant="outlined"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid direction="row" gap="10px" alignSelf="start" grow>
                <NoWrappingText>{t('valid_from_utc')}:</NoWrappingText>
                <StyledDatePicker
                  // disabled={isPlantDateFieldDisabled}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} />
                  )}
                  value={couponEdit.validFromUTC}
                  label={t('valid_from_utc')}
                  inputFormat="dd-MMM-yy"
                  mask="__-___-__"
                  onChange={(event: unknown) =>
                    handleDateChange(event, 'validFromUTC')
                  }
                  disableMaskedInput
                  PopperProps={{ placement: 'top-start' }}
                />
              </Grid>
              <Grid direction="row" gap="10px" alignSelf="start" grow>
                <NoWrappingText>{t('valid_to_utc')}:</NoWrappingText>
                <StyledDatePicker
                  // disabled={isPlantDateFieldDisabled}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} />
                  )}
                  value={couponEdit.validToUTC}
                  label={t('valid_to_utc')}
                  inputFormat="dd-MMM-yy"
                  mask="__-___-__"
                  onChange={(event: unknown) =>
                    handleDateChange(event, 'validToUTC')
                  }
                  disableMaskedInput
                  PopperProps={{ placement: 'top-start' }}
                />
              </Grid>
              <Grid direction="row" gap="10px" alignSelf="start" grow>
                <NoWrappingText>{t('grower_id')}:</NoWrappingText>
                <Input
                  required
                  name="growerId"
                  label={t('grower_id')}
                  id="grower_id"
                  value={couponEdit.growerId}
                  variant="outlined"
                  type="number"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid direction="row" gap="10px" alignSelf="start" grow>
                <NoWrappingText>{t('max_transactions')}:</NoWrappingText>
                <Input
                  required
                  label={t('max_transactions')}
                  id="max_transactions"
                  name="maxTransactions"
                  value={couponEdit.maxTransactions}
                  variant="outlined"
                  type="number"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                direction="row"
                gap="10px"
                alignSelf="start"
                grow
                justify="start"
              >
                <NoWrappingText>{t('eligible_countries')}:</NoWrappingText>
                <StyledMultiSelect
                  id="demo-multiple-name"
                  options={eligibleCountries}
                  value={couponEdit.params.eligibleCountries}
                  onChange={handleChangeSelect}
                />
              </Grid>
            </Grid>
            <Space height={1} />
          </LocalizationProvider>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleSubmitClick} disabled={!isValidForm}>
            <Text textcolor={theme.color.white} size="sm">
              {t('ok')}
            </Text>
          </Button>
          <Button onClick={handleCloseButton}>
            <Text textcolor={theme.color.white} size="sm">
              {t('cancel')}
            </Text>
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default CouponManagementDialog;
